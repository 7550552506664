import { createStore } from 'vuex'
import createPersistedstate from 'vuex-persistedstate'
import {validate} from '@/interface/entrance/login'
import { ElMessage } from 'element-plus'
export default createStore({
  state: {
    // 用户信息
    userInfo:{
      
    },
    fibonacciCount:2
  },
  getters: {
    getCurryLoginManId(state){
      return state.userInfo.id
    },
    getCurryLoginManName(state){
      return state.userInfo.nickname
    }
  },
  mutations: {
    setValue(state, { key, value }) {
      state[key] = value
    },
    updateUserInfo(state,data){
      state.userInfo = data
    }
  },
  actions: {
    // 验证
    async toValidate({commit}){
      let res = await validate()
      if(res.code == 200){
          let token = res.token
          commit('updateUserInfo',res.data[0])
          localStorage.setItem('token',token)
          return true
      }
      ElMessage({
          message: res.message,
          type: 'info'
      })
      return false
    }
  },
  modules: {
  },
  plugins: [
    //默认是存储在localStorage中
    createPersistedstate({
      // key: 存储数据的键名
      key: 'vue3VuexlocalStoragePC',
      //paths:存储state中的那些数据
      paths: ['userInfo']//, 'mode2'
    })
  ]
})