let buttonPermission = [
    
    {
        field:'pushData',
        name:'推送资料'
    },
    {
        field:'Edit',
        name:'编辑'
    },
    // {
    //     field:'freeze',
    //     name:'冻结'
    // },
    {
        field:'Create',
        name:'新增'
    },
    {
        field:'CopyCreate',
        name:'类似创建'
    },
    // {
    //     field:'download',
    //     name:'下载表格'
    // },
    // {
    //     field:'import',
    //     name:'导入表格'
    // },
    // {
    //     field:'export',
    //     name:'导出表格'
    // },
    // {
    //     field:'copyData',
    //     name:'复制数据'
    // },
    // {
    //     field:'delete',
    //     name:'隐藏'
    // },
    // {
    //     field:'deepDelete',
    //     name:'彻底删除'
    // }
]
export {buttonPermission}
export default [
    {
        path: '/dashboard',
        name: 'dashboard',
        meta: {
            title: '系统首页',
            permiss: 'dashboard',
            icon: 'Odometer',
            needLogin:false,
            buttonPermission
        },
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/index.vue'),
    },
    {
        path: '/marriageSeeking',
        name: 'marriageSeeking',
        meta: {
            title: '征婚应用',
            permiss: 'marriageSeeking',
            icon: 'Odometer',
        },
        children:[
            {
                path: '/marriageSeekingRegister',
                name: 'marriageSeekingRegister',
                meta: {
                    title: '征婚登记（红娘）',
                    permiss: 'marriageSeekingRegister',
                    icon: 'Odometer',
                    needLogin:true,
                    buttonPermission
                },
                component: () => import(/* webpackChunkName: "dashboard" */ '@/views/marriageSeeking/marriageSeekingRegister/index.vue'),
            },
            {
                path: '/matching',
                name: 'matching',
                meta: {
                    title: '匹配征婚（红娘）',
                    permiss: 'matching',
                    icon: 'Odometer',
                    needLogin:true,
                    buttonPermission
                },
                component: () => import(/* webpackChunkName: "dashboard" */ '@/views/marriageSeeking/matching/index.vue'),
            },
            {
                path: '/pushSetting',
                name: 'pushSetting',
                meta: {
                    title: '推送设置（红娘）',
                    permiss: 'pushSetting',
                    icon: 'Odometer',
                    needLogin:true,
                    buttonPermission
                },
                component: () => import(/* webpackChunkName: "dashboard" */ '@/views/marriageSeeking/pushSetting/index.vue'),
            },
            {
                path: '/electronicData',
                name: 'electronicData',
                meta: {
                    title: '电子数据（红娘）',
                    permiss: 'electronicData',
                    icon: 'Odometer',
                    needLogin:false,
                    buttonPermission
                },
                component: () => import(/* webpackChunkName: "dashboard" */ '@/views/marriageSeeking/electronicData/index.vue'),
            },
        ]
    },
    {
        path: '/configuration',
        name: 'configuration',
        meta: {
            title: '设置管理',
            permiss: 'configuration',
            icon: 'Odometer',
        },
        children:[
            {
                path: '/dictionaries',
                name: 'dictionaries',
                meta: {
                    title: '数据字典',
                    permiss: 'dictionaries',
                    icon: 'Odometer',
                    needLogin:true,
                    buttonPermission
                },
                component: () => import(/* webpackChunkName: "dashboard" */ '@/views/configuration/dictionaries/index.vue'),
            },
            {
                path: '/jurisdiction',
                name: 'jurisdiction',
                meta: {
                    title: '用户权限',
                    permiss: 'jurisdiction',
                    icon: 'Odometer',
                    needLogin:true,
                    buttonPermission
                },
                component: () => import(/* webpackChunkName: "dashboard" */ '@/views/configuration/jurisdiction/index.vue'),
            },
            {
                path: '/subscriberData',
                name: 'subscriberData',
                meta: {
                    title: '资料管理',
                    permiss: 'subscriberData',
                    icon: 'Odometer',
                    needLogin:false,
                    buttonPermission
                },
                component: () => import(/* webpackChunkName: "dashboard" */ '@/views/configuration/subscriberData/index.vue'),
            },
            {
                path: '/scriptMaintenance',
                name: 'scriptMaintenance',
                meta: {
                    title: '脚本维护',
                    permiss: 'scriptMaintenance',
                    icon: 'Odometer',
                    needLogin:false,
                    buttonPermission
                },
                component: () => import(/* webpackChunkName: "dashboard" */ '@/views/configuration/scriptMaintenance/index.vue'),
            },
            
        ]
    },
];