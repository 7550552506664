

import axios from 'axios'
 
// create an axios instance,根据需要写入配置信息
const service = axios.create({
    baseURL: process.env.NODE_ENV == "development"?"/":"/",
    timeout: 5000,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },//method: 'get', //一般需要用post
    // request timeout
})
 
// request interceptor  请求之前的拦截器
service.interceptors.request.use(config => {
    // Do something before request is sent
    let token = localStorage.getItem("token")
    if(token){
        config.headers.Authorization = 'Bearer ' + token
    }
    return config
}, error => {
    // Do something with request error
    Promise.reject(error)
})
 
// respone interceptor 请求之后异常状态拦截
service.interceptors.response.use(
    response => {
        const res = response.data;
        if (res.statusCode ===  500) {
            return Promise.reject('error');
        } else {
            return response.data;
        }
    },
    error => {
        return Promise.reject(error)
    }
)
 
export default service