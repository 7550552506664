import axios from '@/util/axios.js'
export async function entranceLogin(param){
    let res = await axios({ url: '/user/login', method: 'post', data: { formName: 'user' ,data:param} })
    return res
   
}
export async function entranceRegister(param){
    let res = await axios({ url: '/user/register', method: 'post', data: { formName: 'user' ,data:param} })
    return res
}
export async function validate() {
    let res = await axios({ url: '/user/validate', method: 'post' })
    return res
}