import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import baiduMap from 'vue-baidu-map-3x'
import VueCropper from 'vue-cropper';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import ElementPlus  from 'element-plus'
import Vant from 'vant';
import 'vue-cropper/dist/index.css'
import 'element-plus/dist/index.css';
import './assets/css/icon.css';

 
import 'vant/lib/index.css';
// const fixElTableErr = (table) => {
//     const oldResizeListener = table.methods.resizeListener;
//     table.methods.resizeListener = function () {
//         window.requestAnimationFrame(oldResizeListener.bind(this));
//     };
// };
// // 一定要在Vue.use之前执行此函数
// fixElTableErr(Table);

let app = createApp(App)
// app.use(baiduMap,{
//     ak:'kz26jZQiN4x1rkRAQhW0vTcnUet34OsD'
// })
app.use(store).use(Vant).use(router).use(ElementPlus).use(VueCropper).mount('#app')

// 注册elementplus图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}
